import styled$1 from 'styled-components';
import { space, colorSet } from '@ccg/core';

/* eslint-disable no-irregular-whitespace */
const StyledControls = styled$1.div`
  margin-top: ${space(8)};

  .controls__caption {
    margin-bottom: ${space(3)};
  }

  button {
    margin-right: ${space(3)};
  }

  span {
    color: ${colorSet('color')};

    &.is-active {
      font-weight: 500;
    }
  }
`;

export default StyledControls;
