const typography = {
  scale: {
    ratio: [1.19, 1.28],
    base: [16, 19],
    width: [375, 980],
    breakpoints: 4,
    corrections: {}
  }
};
const fonts = {
  default: {
    family: 'Founders Grotesk, Arial, sans-serif'
  }
};

export { fonts, typography };
