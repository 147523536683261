import { useMemo } from 'react';

import { getFocalPointAlignment, FocalPointPosition } from '../helper/index';

interface FocalPointObject {
  alignment: FocalPointPosition;
  isLeftAligned: boolean;
  isTopAligned: boolean;
  isRightAligned: boolean;
  isBottomAligned: boolean;
}

const useFocalPoint = (
  hasFocalPoint: boolean,
  focalPoint: [number, number],
  randomFallback?: boolean
): FocalPointObject =>
  useMemo(() => {
    const alignment = getFocalPointAlignment(hasFocalPoint, focalPoint, randomFallback);

    const isLeftAligned = alignment.toLowerCase().includes('left');
    const isTopAligned = alignment.toLowerCase().includes('top');
    const isRightAligned = !isLeftAligned;
    const isBottomAligned = !isTopAligned;

    return { alignment, isLeftAligned, isTopAligned, isRightAligned, isBottomAligned };
  }, [hasFocalPoint, focalPoint, randomFallback]);

export default useFocalPoint;
