import styled$1 from 'styled-components';
import { space, mq, getProperty, colorSet } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';

const StyledInlineContact = styled$1.div`
  position: relative;

  margin-top: ${space(8)};
  margin-bottom: ${space(8)};

  ${mq('m')} {
    margin-top: ${space(22)};
    margin-bottom: ${space(16)};
  }

  .inlineContact__link {
    ${getGrid()};
    border-bottom: none;

    p {
      ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 7'
})};
      margin-top: ${space(4)};
    }
  }

  .inlineContact__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};

    h2 {
      position: relative;
      display: inline;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 2px;
        background: ${colorSet('color')};
        transition: width 200ms ease-in-out;
      }
    }
  }

  .inlineContact__link:hover {
    h2:after {
      right: auto;
      left: 0;
      width: 100%;
    }
  }
`;

export default StyledInlineContact;
