import styled$1 from 'styled-components';
import { space, mq, getProperty } from '@ccg/core';
import Grid$1 from '../Grid/Grid.js';

const StyledTeaserOverview = styled$1(Grid$1)`
  position: relative;
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};
  row-gap: ${space(4)};

  ${mq('m')} {
    margin-top: ${space(16)};
  }

  & + .teaserContainer {
    margin-top: ${space(8)};
  }

  .teaserContainer__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 8'
})};
  }

  .teaserContainer__subline {
    ${getProperty('grid-column', {
  default: '1 / span 6'
})};
  }

  .teaserContainer__link {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    justify-self: end;

    svg {
      width: 26px;
      height: 19px;
      transform: translateY(2px);
      transition: transform 400ms ease-in-out;
    }

    a:hover {
      svg {
        transform: translateY(2px) translateX(${space(1)});
      }
    }
  }
`;

export default StyledTeaserOverview;
