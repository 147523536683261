import styled$1, { css } from 'styled-components';
import { getProperty, space, mq, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getGrid } from '../Grid/Grid.js';

const StyledTeaser = styled$1.div`
  ${getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 6'
})};

  .teaser__link {
    ${getGrid({
  default: 6
}, false)};
    height: 100%;
  }

  .teaser__media {
    ${getProperty('grid-column', {
  default: '1 / span 6'
})};
    margin-bottom: ${space(4)};

    ${mq('m')} {
      margin-bottom: ${space(8)};
    }

    img,
    video {
      display: block;
      width: 100%;
      overflow: hidden;
      transition: transform 0.2s;
      object-fit: cover;
    }
  }

  .teaser__content {
    ${getProperty('grid-column', {
  default: '1 / span 5'
})};
    position: relative;
    align-self: center;
  }

  .teaser__topic {
    margin-bottom: ${space(2)};
    font-weight: 500;
  }

  .teaser__headline {
    position: relative;
    display: inline-block;
    word-break: break-word;
    transition: transform 0.4s;
    will-change: transform;
  }

  .teaser__subline {
    margin-top: ${space(4)};
  }

  ${({
  teaserType,
  hasMedia
}) => {
  switch (teaserType) {
    case '/news':
      return css([".teaser__media{margin-bottom:0;}", ""], hasMedia && css([".teaser__content{margin-top:", ";padding-top:", ";background:", ";transition:background 200ms ease-in-out;}.teaser__headline{padding-right:", ";}"], space(-12), space(6), colorSet('background'), space(1)));

    case '/standard':
      return css(["", ";.teaser__link{", ";&:hover{span span:after{right:auto;left:0;width:100%;}}}.teaser__content{", ";", ";}.teaser__headline{", ";", ";position:relative;span span{position:relative;&:after{content:'';position:absolute;bottom:0;right:0;width:0;height:2px;background:", ";transition:width 200ms ease-in-out;}}}.teaser__subline{", ";}"], getProperty('grid-column-end', {
        default: 'span 6',
        m: 'span 12'
      }), getGrid({
        default: 6,
        m: 12
      }, false), getProperty('grid-column', {
        default: '1 / span 6',
        m: '1 / span 12'
      }), getGrid({
        default: 6,
        m: 12
      }, false), getProperty('grid-column', {
        default: '1 / span 6',
        m: '1 / span 12'
      }), getTypography.headline, colorSet('color'), getProperty('grid-column', {
        default: '1 / span 6'
      }));

    default:
      return null;
  }
}};

  ${({
  featured
}) => featured && css(["", ";.teaser__link{", ";}.teaser__media{", ";}.teaser__content{", ";}"], getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 12'
}), getGrid({
  default: 6,
  m: 12
}, false), getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
}), getProperty('grid-column', {
  default: '1 / span 5',
  m: '1 / span 9'
}))};

  ${({
  related,
  hasMedia
}) => related && css(["", ";.teaser__link{", ";}.teaser__media{", ";}.teaser__content{", ";margin-top:", ";padding-top:", ";}"], getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 4'
}), getGrid({
  default: 6,
  m: 4
}, false), getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 4'
}), getProperty('grid-column', {
  default: '1 / span 5',
  m: '1 / span 3'
}), hasMedia && space(-6), hasMedia && space(4))};
`;

export default StyledTeaser;
