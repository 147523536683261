import styled$1 from 'styled-components';
import { colorSet } from '@ccg/core';
import { getOuterGridContainer, getInnerGridContainer } from '../Grid/Grid.js';

const StyledMainContainer = styled$1.main`
  ${getOuterGridContainer()}
  position: relative;
  overflow: hidden;
  background: ${colorSet('background')};
  color: ${colorSet('color')};
  transition: transform 0.7s cubic-bezier(0.77, 0, 0.77, 1), background 0.1s 0.8s ease-in-out,
    color 0.1s 0.8s ease-in-out;
  min-height: 100vh;

  .mainContainer__inner {
    ${getInnerGridContainer()}
    position: relative;
  }
`;

export default StyledMainContainer;
