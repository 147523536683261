import styled$1 from 'styled-components';
import { space } from '@ccg/core';

/* eslint-disable no-irregular-whitespace */
const StyledCaption = styled$1.div`
  margin: ${space(4)} 0;
  transition: all 0.7s 0.4s ease-in-out;
  opacity: ${({
  isVisible
}) => isVisible ? 1 : 0};
  transform: ${({
  isVisible
}) => isVisible ? 'translateY(0)' : 'translateY(30px)'};

  .caption__caption {
    p {
      margin: 0;
    }
  }

  .caption__credits {
    margin-top: ${space(2)};
  }
`;

export default StyledCaption;
