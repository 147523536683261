import styled$1 from 'styled-components';
import { space, mq, getProperty, colorSet } from '@ccg/core';
import Grid$1 from '../Grid/Grid.js';

const StyledFacs = styled$1(Grid$1)`
  position: relative;
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};
  row-gap: ${space(8)};

  ${mq('m')} {
    margin-top: ${space(16)};
    margin-bottom: ${space(16)};
    row-gap: ${space(12)};
  }

  .facts__fact {
    ${({
  hasRightAlignment
}) => getProperty('grid-column', {
  default: '1 / span 3',
  m: hasRightAlignment ? '7 / span 3' : '1 / span 3',
  l: hasRightAlignment ? '7 / span 2' : '1 / span 2'
})};

    @media (min-width: 768px) and (max-width: 1280px) {
      margin-right: ${space(0.5)};
    }

    &:nth-child(even) {
      ${({
  hasRightAlignment
}) => getProperty('grid-column', {
  default: '1 / span 3',
  m: hasRightAlignment ? '10 / span 3' : '4 / span 3',
  l: hasRightAlignment ? '10 / span 2' : '4 / span 2'
})};

      @media (min-width: 768px) and (max-width: 1280px) {
        margin-right: 0;
        margin-left: ${space(0.5)};
      }
    }

    position: relative;
    border-bottom: 2px solid ${colorSet('color')};
    padding-bottom: ${space(8)};
    color: ${colorSet('color')};

    .highlight,
    .highlight-small {
      margin-bottom: ${space(4)};
    }

    p {
      margin-bottom: ${space(2)};
      word-break: break-word;
      hyphens: auto;

      &:last-child {
        margin: 0;
        padding: 0;
      }
    }
  }
`;

export default StyledFacs;
