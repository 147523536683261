import { css } from 'styled-components';
import { mq } from '@ccg/core';
import adaptiveModularScale$1 from 'adaptive-modular-scale';
import { step } from '@4rk/modular-system';

/**
 * Returns adaptive modular font sizes
 * @param  {number} step - Step on the modular scale. Default: 0.
 * @param  {array} corrections - Corrections array. Default: null.
 * @returns {string} Resulting adaptive modular font sizes
 * @example ${fontSize(3, [-1, 2])};
 */

const fontSize = (step = 0, corrections = null) => ({
  theme
}) => {
  const {
    typography
  } = theme;
  const {
    scale: {
      width,
      base,
      ratio,
      breakpoints
    }
  } = typography;
  const corr = corrections ? {
    [step]: corrections
  } : {};
  return adaptiveModularScale$1(step, {
    ratio,
    base,
    width,
    breakpoints: parseFloat(breakpoints),
    corrections: corr
  })({
    theme
  });
};
const fontFamily = (key = 'default') => props => {
  const family = step('form', 'fontFamily')(props);
  return `font-family: ${family[key]};`;
};
/**
 * Returns font declarations
 * @param  {number} step - Step on the modular scale. Default: 0.
 * @param  {string} familyKey - Key of font family inside theme.fonts object. Default: "default".
 * @returns {string} Resulting font declarations
 * @example ${font(3, "accent")};
 */

const font = (step = 0, familyKey = 'default') => ({
  theme
}) => {
  const {
    typography: {
      scale: {
        corrections
      }
    }
  } = theme;
  const corr = corrections && corrections[familyKey] ? corrections[familyKey][step] : null;
  const family = fontFamily(familyKey)({
    theme
  });
  const fontSizes = fontSize(step, corr)({
    theme
  });
  return `${family}\n${fontSizes}`;
};
/* COPIES */

const copy = css(["", " line-height:1.4;letter-spacing:0.2px;"], font(0));
const copyIntro = css(["", " line-height:1.2;letter-spacing:0.25px;", "{letter-spacing:0.4px;}"], font(1), mq('m'));
const subline = css(["", " line-height:1.2;letter-spacing:0.25px;font-weight:500;", "{letter-spacing:0.4px;}"], font(1), mq('m'));
const subheadline = css(["", " line-height:1.1;letter-spacing:0.3px;", "{letter-spacing:0.4px;}"], font(3), mq('m'));
const headline = css(["", " line-height:1.1;letter-spacing:0.28px;font-weight:500;", "{letter-spacing:0.4px;}"], font(5), mq('m'));
const hero = css(["", " line-height:1.05;letter-spacing:0.45px;font-weight:500;", "{letter-spacing:0.5px;line-height:0.97;}"], font(7), mq('m'));
const getTypography = {
  copy,
  copyIntro,
  headline,
  subline,
  subheadline,
  hero
};

export { copy, copyIntro, font, fontFamily, fontSize, getTypography, headline, hero, subheadline, subline };
