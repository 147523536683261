import styled$1 from 'styled-components';
import { space, mq, colorSet } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';
import { getImageAlignment, getCaptionAlignment } from './helper/index.js';

/* eslint-disable indent */
const StyledMediaContent = styled$1.div`
  ${getGrid()}
  position: relative;
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};
  padding: 0;

  ${mq('m')} {
    margin-top: ${space(16)};
    margin-bottom: ${space(16)};
  }

  .mediaContent__media-wrapper {
    ${getImageAlignment}

    img,
    video {
      width: 100%;
      display: block;
    }
  }

  .mediaContent__credits {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: top left;
    right: -20px;
    color: ${colorSet('accent')};
    left: -20px;
  }

  .mediaContent__meta {
    ${getCaptionAlignment}
    display: flex;
    justify-content: ${({
  isRightAligned
}) => isRightAligned ? 'flex-start' : 'flex-end'};
    align-items: center;
    width: 100%;
    margin-top: ${space(4)};
    margin-bottom: 0;

    ${mq('m')} {
      margin-top: ${space(8)};
    }
  }
`;

export default StyledMediaContent;
