import { css } from 'styled-components';
import { getProperty } from '@ccg/core';

/* eslint-disable no-nested-ternary */
const getCaptionAlignment = ({
  variant,
  inline,
  isRightAligned: right
}) => {
  let s = inline ? '1 / span 4' : '1 / span 6';
  let m = inline ? '1 / span 4' : right ? '6 / span 6' : '2 / span 6';

  if (variant === 'm') {
    s = inline ? '1 / span 4' : '1 / span 6';
    m = inline ? '1 / span 6' : right ? '8 / span 5' : '1 / span 5';
  } else if (variant === 'l') {
    s = '1 / span 6';
    m = '1 / span 9';
  } else if (variant === 'xl') {
    s = '1 / span 6';
    m = '1 / span 12';
  }

  return css(["", " ", ";"], getProperty('grid-row', {
    m: inline ? 2 : 1
  }), getProperty('grid-column', {
    default: s,
    m
  }));
};
const getImageAlignment = ({
  variant,
  inline,
  isRightAligned: right
}) => {
  let s = inline ? '1 / span 4' : right ? '1 / span 4' : '3 / span 4';
  let m = inline ? '1 / span 4' : right ? '1 / span 4' : '9 / span 4';

  if (variant === 'm') {
    s = inline ? '1 / span 4' : right ? '1 / span 4' : '3 / span 4';
    m = inline ? '1 / span 6' : right ? '1 / span 6' : '7 / span 6';
  } else if (variant === 'l') {
    s = '1 / span 6';
    m = '1 / span 9';
  } else if (variant === 'xl') {
    s = '1 / span 6';
    m = '1 / span 12';
  }

  return css(["grid-row:1;", ";"], getProperty('grid-column', {
    default: s,
    m
  }));
};

export { getCaptionAlignment, getImageAlignment };
