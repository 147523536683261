import styled$1 from 'styled-components';
import { Spinner as Spinner$1, colorSet } from '@ccg/core';

var Spinner = styled$1(Spinner$1)`
  div {
    border-top-color: ${colorSet('color')};
  }
`;

export default Spinner;
