import styled$1 from 'styled-components';
import { space, mq, getProperty } from '@ccg/core';

/* eslint-disable no-irregular-whitespace */
const StyledQuote = styled$1.div`
  position: relative;
  margin: ${space(10)} 0;
  padding: 0;

  ${mq('m')} {
    margin: ${space(16)} 0;
  }

  cite {
    position: relative;
    font-style: normal;
    display: block;
  }

  .quote__content {
    ${props => props.hasPhoto ? getProperty('grid-column', {
  default: '1 / span 6',
  m: '5 / span 8',
  l: '4 / span 8'
}) : getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 8'
})};
  }

  .quote__text {
    margin: ${space(10)} 0 ${space(4)} 0;
    margin-top: ${props => !props.hasPhoto && 0};

    ${mq('m')} {
      margin: ${space(8)} 0 ${space(4)} 0;
      margin-top: ${props => !props.hasPhoto && 0};
    }
  }

  .quote__author-image-wrapper  {
    ${getProperty('grid-column', {
  default: '1 / span 3',
  l: '1 / span 2'
})};
    align-self: start;
  }

  .quote__author-image {
    overflow: hidden;
  }
`;

export default StyledQuote;
