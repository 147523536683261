const colors = {
  bright: '#4243C8',
  light: '#fff',
  dark: '#000',
  highlight: '#c3bcb5'
};
const colorSets = {
  neutral: {
    color: colors.light,
    colorSecondary: colors.dark,
    background: colors.dark,
    backgroundSecondary: colors.highlight,
    accent: colors.bright
  },
  editorial: {
    color: colors.dark,
    colorSecondary: colors.light,
    background: colors.light,
    backgroundSecondary: colors.highlight,
    accent: colors.bright
  }
};

export { colorSets, colors };
