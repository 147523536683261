const grid = {
  columns: {
    default: 6,
    m: 12
  },
  gutters: {
    default: '0',
    m: '15px',
    l: '30px'
  }
};

export { grid };
