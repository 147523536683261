import styled$1, { css } from 'styled-components';
import { zIndex, color, colorSet, mq, space } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getTransitionProperty } from '../../styles/transitions.js';
import { getOuterGridContainer, getInnerGridContainer, getOuterMargins } from '../Grid/Grid.js';

/* eslint-disable no-nested-ternary */
const StyledNavigation = styled$1.div`
  position: relative;
  width: 100%;

  .navigation__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    ${getOuterGridContainer()};
  }

  .navigation__inner {
    ${getInnerGridContainer()};
    position: relative;
    width: 100%;
  }

  .navigation__list {
    ${getOuterGridContainer()};
    position: fixed;
    z-index: ${zIndex('middle')};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${color('light')};
    background: ${colorSet('accent')};
    overflow-y: ${props => props.isOpen ? 'auto' : 'hidden'};
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(-100%)'};
    ${getTransitionProperty('all')};

    ${mq('m')} {
      padding-top: 89px;
    }
  }

  .navigation__list-inner {
    ${getInnerGridContainer()};
    height: 100%;
    margin: ${space(12)} 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mq('m')} {
      justify-content: center;
      margin: ${space(20)} 0 ${space(12)} 0;
    }
  }

  ul,
  ol {
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  li {
    position: relative;
    display: block;
  }

  ol {
    counter-reset: section;

    & > li a::before {
      position: absolute;
      bottom: 8px;
      left: 0;
      color: ${color('light')};
      counter-increment: section;
      content: '0' counters(section, '.');
      ${getTypography.copyIntro};

      ${mq('m')} {
        bottom: 7px;
      }
    }
  }

  .navigation__mainlist,
  .navigation__secondarylist {
    ${getOuterMargins()};
    opacity: 0;

    ${mq('m')} {
      margin-top: auto;
    }
  }

  .navigation__mainlist {
    position: relative;

    li {
      margin-bottom: ${space(8)};

      ${mq('m')} {
        margin-bottom: ${space(16)};
      }
    }

    li:first-child {
      ${mq('m', 'max')} {
        margin-top: ${space(24)};
      }
    }

    li a {
      display: inline-block;
      padding-left: ${space(10)};
      transition: padding 300ms ease-in-out;

      ${mq('m')} {
        display: inline-block;
        padding-left: ${space(16)};
      }

      &:hover {
        padding-left: ${space(13)};

        ${mq('m')} {
          padding-left: ${space(24)};
        }
      }
    }
  }

  .navigation__secondarylist {
    display: block;

    ${mq('m')} {
      display: flex;
      justify-content: flex-end;
    }

    li a {
      display: block;
      margin-top: ${space(4)};

      ${mq('m')} {
        margin-top: 0;
        margin-left: ${space(10)};
      }
    }
  }

  .navigation__header {
    position: relative;
    z-index: ${zIndex('top')};
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: ${space(8)};
    padding-right: ${space(4)};
    padding-left: ${space(4)};

    ${mq('m')} {
      padding-top: ${space(12)};
      padding-right: ${space(10)};
      padding-left: ${space(10)};
    }

    ${mq('l')} {
      padding-right: ${space(13)};
      padding-left: ${space(13)};
    }
  }

  .navigation__logo {
    position: relative;
    display: block;
    width: 119px;
    height: 20px;

    ${mq('m')} {
      width: 166px;
      height: 28px;
    }

    svg {
      position: absolute;
      fill: ${props => props.theme.clientCi.text && !props.isOpen ? props.theme.clientCi.text : props.isOpen ? color('light') : colorSet('color')};
      width: 100%;
      height: 100%;
    }
  }

  .navigation__toggle {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    color: ${props => props.theme.clientCi.text && !props.isOpen ? props.theme.clientCi.text : props.isOpen ? color('light') : colorSet('color')};
    ${getTransitionProperty('all')};

    span:nth-child(2) {
      margin-left: ${space(4)};

      ${mq('m', 'max')} {
        display: none;
      }
    }
  }

  .navigation__toggle-icon {
    position: relative;
    width: 16px;
    height: 16px;

    ${mq('m')} {
      width: 26px;
      height: 26px;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      width: 100%;
      height: 2px;
      background: ${props => props.theme.clientCi.text && !props.isOpen ? props.theme.clientCi.text : props.isOpen ? color('light') : colorSet('color')};
      ${getTransitionProperty('all')};
      transform-origin: center;

      ${mq('m')} {
        top: 12px;
      }
    }

    &:after {
      transform: ${({
  isOpen
}) => isOpen ? 'rotate(0)' : 'rotate(90deg)'};
    }
  }

  .navigation__lang {
    margin-left: ${space(8)};
    ${getTransitionProperty('all')};
    color: ${props => props.theme.clientCi.text && !props.isOpen ? props.theme.clientCi.text : props.isOpen ? color('light') : colorSet('color')};
  }

  ${props => props.isOpen && css([".navigation__mainlist,.navigation__secondarylist{opacity:1;}.navigation__header{background:", ";}.navigation__mainlist,.navigation__secondarylist,.navigation__header{", ";}"], colorSet('accent'), getTransitionProperty('all', '300ms'))};
`;

export default StyledNavigation;
