import styled$1, { css } from 'styled-components';

const getDirectionCSS = props => {
  if (props.direction === 'down') {
    return css(["transform:translateY(-30px);"]);
  }

  return css(["transform:translateY(30px);"]);
};

const StyledRevealBox = styled$1.div`
  position: relative;
  overflow: hidden;
  opacity: 0;
  ${getDirectionCSS}
  transition: all 0.7s 0.4s ease-in-out;

  ${({
  isVisible
}) => isVisible && css(["opacity:1;transform:translateY(0);"])};
`;

export default StyledRevealBox;
