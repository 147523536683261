import styled$1 from 'styled-components';
import { getProperty, colorSet, space, mq } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getGrid } from '../Grid/Grid.js';

const StyledJobTeaser = styled$1.div`
  ${getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 12'
})};
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${colorSet('color')};
  padding-bottom: ${space(4)};

  &:last-child {
    border-bottom: 0;
  }

  ${mq('m')} {
    padding-bottom: ${space(8)};
  }

  .jobTeaser__content {
    ${getGrid({
  default: 6,
  m: 12
}, false)};
    position: relative;
  }

  .jobTeaser__locations {
    font-weight: 500;
    margin-bottom: ${space(2)};
    width: 100%;

    ${mq('m')} {
      width: 41%;
    }
  }

  .jobTeaser__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 5'
})};
  }

  .jobTeaser__description {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '7 / span 6'
})};
    margin-top: ${space(4)};
    ${getTypography.copyIntro};

    ${mq('m')} {
      margin-top: ${space(2)};
    }

    p {
      margin-bottom: ${space(3)};

      ${mq('m')} {
        margin-bottom: ${space(4)};
      }
    }
  }

  .jobTeaser__link-text {
    position: relative;
    display: inline;
    font-weight: 500;

    svg {
      width: 16px;
      height: 12px;
      transform: translateY(2px);
      transition: padding 200ms ease-in-out;

      ${mq('m', 'max')} {
        display: none;
      }

      ${mq('m')} {
        transform: translateY(1px);
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 1px;
      background: ${colorSet('color')};
      transition: width 200ms ease-in-out;
    }
  }

  &:hover {
    .jobTeaser__link-text:after {
      right: auto;
      left: 0;
      width: 100%;
    }

    .jobTeaser__link-text svg {
      padding-left: ${space(2)};
    }
  }
`;

export default StyledJobTeaser;
