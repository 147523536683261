import styled$1 from 'styled-components';
import { space, colorSet, mq, getProperty } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';

/* eslint-disable no-irregular-whitespace */
const StyledFooter = styled$1.div`
  position: relative;
  margin: ${space(20)} 0 ${space(16)} 0;

  .footer__wrapper {
    ${getGrid()};
    padding: ${space(8)} 0;
    border-top: 1px solid ${colorSet('color')};
    border-bottom: 1px solid ${colorSet('color')};

    ${mq('m')} {
      padding: ${space(16)} 0;
    }

    ${mq('m', 'max')} {
      row-gap: ${space(10)};
    }
  }

  .footer__subline {
    margin-bottom: ${space(4)};
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .footer__address {
    ${getProperty('grid-column-end', {
  default: 'span 4',
  m: 'span 3'
})};
  }

  .footer__main-navigation {
    ${getProperty('grid-column-end', {
  default: 'span 4',
  m: 'span 3'
})};
  }

  .footer__social-media {
    ${getProperty('grid-column-end', {
  default: 'span 4',
  m: 'span 3'
})};

    a {
      text-transform: capitalize;
    }
  }

  .footer__icon {
    position: absolute;
    transform: rotate(10deg);
    right: ${space(4)};
    width: 75px;
    height: 78px;

    ${mq('m')} {
      right: ${space(10)};
    }

    ${mq('l')} {
      right: ${space(13)};
      width: 100px;
      height: 104px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export default StyledFooter;
