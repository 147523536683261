import styled$1 from 'styled-components';
import { colorSet, space, mq } from '@ccg/core';

const StyledArticleVideo = styled$1.div`
  width: 100%;
  position: relative;

  .video__play-button {
    display: block;
    width: 100%;
    cursor: pointer;
  }

  .video__poster {
    background: ${colorSet('background')};
    position: relative;

    img {
      opacity: 0.8;
    }
  }

  .video__play-overlay {
    height: 100%;
    position: absolute;
    top: ${space(4)};
    left: ${space(4)};
    pointer-events: none;

    ${mq('m')} {
      top: ${space(6)};
      left: ${space(6)};
    }
  }

  .video__button-label {
    display: flex;
    align-items: center;
    line-height: 1;

    svg {
      margin-right: ${space(4)};

      ${mq('m', 'max')} {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export default StyledArticleVideo;
