import styled$1, { css } from 'styled-components';
import { Link, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';

const StyledLink = styled$1(Link)`
  position: relative;
  text-transform: ${({
  transform
}) => transform};
  ${({
  variant
}) => getTypography[variant]};
  display: inline;

  ${({
  noHover,
  invertedHover,
  variant
}) => !noHover && css(["&:after{content:'';position:absolute;bottom:0;right:0;width:", ";height:", ";background:", ";transition:width 200ms ease-in-out;}&:hover:after{right:auto;left:0;width:", ";}"], invertedHover ? 0 : '100%', variant === 'headline' || variant === 'hero' ? '2px' : '1px', colorSet('color'), invertedHover ? '100%' : 0)}
`;

export default StyledLink;
