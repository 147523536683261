import { breakpoints as breakpoints$1 } from './breakpoints.js';
import { colors as colors$1, colorSets } from './colors.js';
import { grid as grid$1 } from './grid.js';
import { modular } from './modularSystem.js';
import { spacing as spacing$1 } from './spacing.js';
import { typography as typography$1, fonts } from './typography.js';
import { zIndexes as zIndexes$1 } from './zIndexes.js';

const tokens = {
  breakpoints: breakpoints$1,
  colors: colors$1,
  colorSets,
  grid: grid$1,
  modular,
  spacing: spacing$1,
  typography: typography$1,
  fonts,
  zIndexes: zIndexes$1
};

export { tokens };
