import styled$1, { css } from 'styled-components';
import { Text, space, colorSet, mq } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getTextColumns } from './helper/getGridColumns.js';
import { getGrid } from '../../components/Grid/Grid.js';

const StyledText = styled$1(Text)`
  position: relative;

  h2,
  h3 {
    margin: 0 0 ${space(4)};
    ${getTypography.subheadline}
  }

  h4 {
    margin: 0 0 ${space(4)};
    ${getTypography.subline}
  }

  ul,
  ol {
    padding: 0;
    margin: 0 0 ${space(4)};
  }

  li {
    list-style-type: none;
    position: relative;
    margin: 0 0 0 ${space(5)};
    ${getTypography.copy}
  }

  ul > li:before {
    position: absolute;
    content: '';
    background: ${colorSet('color')};
    top: 10px;
    left: ${space(-5)};
    border-radius: 50%;
    width: 5px;
    height: 5px;

    ${mq('m')} {
      top: 12px;
    }
  }

  ul ul,
  ol ul {
    margin: ${space(2)} 0;

    & > li:before {
      top: 12px;
      width: 4px;
      height: 4px;
    }
  }

  ol {
    counter-reset: section;

    & > li::before {
      position: absolute;
      left: ${space(-5)};
      color: ${colorSet('color')};
      counter-increment: section;
      content: counters(section, '.');
      ${getTypography.small};
    }
  }

  ol ol,
  ul ol {
    counter-reset: section;
    margin: ${space(2)} 0;

    & > li::before {
      counter-increment: section;
      content: counter(section, lower-alpha) '. ';
    }
  }

  p {
    margin: 0 0 ${space(4)};
    ${getTypography.copy}
  }

  a {
    position: relative;
    font-weight: 500;
    display: inline;
    border-bottom: 1px solid ${colorSet('color')};

    ${mq('m')} {
      display: inline-block;
      border-bottom: 0;
    }

    &:after {
      ${mq('m')} {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background: ${colorSet('color')};
        transition: width 200ms ease-in-out;
      }
    }

    &:hover:after {
      right: auto;
      left: 0;
      width: 0;
    }
  }

  b,
  strong {
    font-weight: 500;
  }

  .highlight {
    ${getTypography.subheadline};
  }

  .highlight-small {
    ${getTypography.subline};
  }

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  > ul,
  > ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${props => props.hasGrid && css(["", " margin-top:", ";margin-bottom:", ";", "{margin-top:", ";margin-bottom:", ";}h2,h3,h4,h5,h6,p,ul,ol{", "}"], getGrid(), space(10), space(10), mq('m'), space(16), space(16), getTextColumns)}

  ${({
  isVisible,
  reveal
}) => reveal && css(["transition:all 0.7s 0.4s ease-in-out;opacity:", ";transform:", ";"], isVisible ? 1 : 0, isVisible ? 'translateY(0)' : 'translateY(30px)')}
`;

export default StyledText;
