import styled$1 from 'styled-components';
import { getProperty, mq, colorSet, space } from '@ccg/core';
import Text$1 from '../../basics/Text/Text.js';

const StyledFact = styled$1(Text$1)`
  position: relative;

  > p {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 6'
})};

    ${mq('m')} {
      margin-bottom: 0;
    }
  }

  .fact__content {
    ${getProperty('grid-column', {
  default: '1 / span 5',
  m: '8 / span 3'
})};
    position: relative;
    align-self: center;
    color: ${colorSet('color')};
    border-bottom: 2px solid ${colorSet('color')};
    padding-bottom: ${space(10)};

    .highlight,
    .highlight-small {
      margin-bottom: ${space(4)};
    }

    p {
      margin-bottom: ${space(2)};

      &:last-child {
        margin: 0;
        padding: 0;
      }
    }
  }
`;

export default StyledFact;
