import styled$1 from 'styled-components';
import { space, mq, getProperty } from '@ccg/core';
import Grid$1 from '../Grid/Grid.js';

const StyledMediaRow = styled$1(Grid$1)`
  position: relative;
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};
  row-gap: ${space(3)};

  ${mq('m')} {
    margin-top: ${space(16)};
    margin-bottom: ${space(16)};
  }

  ${mq('l')} {
    row-gap: ${space(6)};
  }

  .mediaRow__item {
    ${props => props.itemsPerRow === '2' ? getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 6'
}) : getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 4'
})};

    img,
    video {
      width: 100%;
      display: block;
    }
  }

  .mediaRow__item-meta {
    margin-top: ${space(4)};
    margin-bottom: 0;

    ${mq('m')} {
      margin-top: ${space(8)};
    }
  }
`;

export default StyledMediaRow;
