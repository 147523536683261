import styled$1 from 'styled-components';
import { getProperty, space, mq, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getGrid } from '../Grid/Grid.js';

const StyledCategoryTeaser = styled$1.div`
  ${getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 6'
})};
  ${getGrid({
  default: 6
}, false)};

  .categoryTeaser__link {
    position: relative;
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 5'
})};
    height: 100%;

    > div {
      padding-top: ${space(4)};

      ${mq('m')} {
        padding-top: ${space(6)};
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 58px;
        height: 1px;
        background: ${colorSet('color')};

        ${mq('m')} {
          width: 69px;
        }
      }
    }
  }

  .categoryTeaser__headline {
    position: relative;
    display: inline;

    ${getTypography.subheadline};

    h2 {
      display: inline;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 2px;
      background: ${colorSet('color')};
      transition: width 200ms ease-in-out;
    }
  }

  .categoryTeaser__subline {
    margin-top: ${space(4)};
  }

  .categoryTeaser__icon {
    transition: padding 200ms ease-in-out;
    padding-left: ${space(1)};

    svg {
      width: 31px;
      height: 23px;
      transform: translateY(2px);
    }

    ${mq('m', 'max')} {
      display: none;
    }
  }

  .categoryTeaser__link:hover {
    .categoryTeaser__headline:after {
      right: auto;
      left: 0;
      width: 100%;
    }

    .categoryTeaser__icon {
      padding-left: ${space(2)};
    }
  }
`;

export default StyledCategoryTeaser;
