import styled$1, { css } from 'styled-components';
import { space, mq } from '@ccg/core';
import { getOuterMargins } from '../Grid/Grid.js';

/* eslint-disable no-nested-ternary */
const StyledTeaserContainer = styled$1.div`
  position: relative;
  min-height: ${({
  hasMinHeight
}) => hasMinHeight && '400px'};
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};

  ${mq('m')} {
    margin-top: ${space(16)};
    margin-bottom: ${space(16)};
  }

  .teaserOverview + & {
    padding-top: 0;
  }

  .teaserContainer__spinner {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .teaserContainer__no-results {
    grid-column: 1 / -1;

    button {
      margin: ${space(8)} 0;
    }
  }

  .teaserContainer__wrapper {
    position: relative;
    row-gap: ${({
  hasJobTeaser,
  hasCategoryTeaser
}) => hasJobTeaser ? space(4) : hasCategoryTeaser ? space(8) : space(16)};
    align-items: start;

    ${mq('m')} {
      row-gap: ${({
  hasJobTeaser,
  hasCategoryTeaser
}) => hasJobTeaser ? space(8) : hasCategoryTeaser ? space(16) : space(24)};
    }

    ${props => props.filtering && css(["opacity:0.3;"])}
  }

  .teaserContainer__load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${space(12)} 0;
    ${getOuterMargins};
  }
`;

export default StyledTeaserContainer;
