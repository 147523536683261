import styled$1, { css } from 'styled-components';
import { space, mq, getProperty, colorSet } from '@ccg/core';

const StyledStage = styled$1.div`
  position: relative;
  margin-bottom: ${({
  variant
}) => variant === 'home' ? space(20) : space(10)};

  ${mq('m')} {
    margin-bottom: ${({
  variant
}) => // eslint-disable-next-line no-nested-ternary
variant === 'home' ? space(28) : variant === 'standard' ? space(-6) : space(16)};
  }

  .stage__image {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    ${getProperty('grid-row', {
  m: '2'
})};
    margin-bottom: ${space(10)};

    ${mq('m')} {
      margin-bottom: ${space(16)};
    }
  }

  img,
  video {
    width: 100%;
    object-fit: cover;
  }

  .stage__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12',
  l: '1 / span 10'
})};
    ${getProperty('grid-row', {
  m: '1'
})};
    position: relative;
    margin-bottom: ${({
  hasOnlyHeadline
}) => !hasOnlyHeadline && space(10)};
    padding-top: ${space(22)};

    ${mq('m')} {
      margin-bottom: ${({
  hasOnlyHeadline
}) => !hasOnlyHeadline && space(16)};
      padding-top: 30%;
    }

    ${({
  variant
}) => variant === 'home' && css(["margin-bottom:", ";", "{margin-bottom:", ";}"], space(6), mq('m'), space(7))}

    ${props => props.theme.clientCi.background && props.theme.clientCi.text && css(["color:", ";padding-bottom:", ";", "{padding-bottom:", ";}&:before{content:'';position:absolute;top:0;margin-left:", ";width:100vw;height:100%;background:", ";", "{margin-left:", ";}", "{margin-left:", ";}", "{left:calc((100vw - 1440px) / -2);}}", ""], props.theme.clientCi.text, space(10), mq('m'), space(16), space(-4), props.theme.clientCi.background, mq('m'), space(-10), mq('l'), space(-13), mq('xl'), props.hasMedia && css(["margin-bottom:0;padding-bottom:", ";& + .stage__image{margin-top:", ";}", "{margin-bottom:0;padding-bottom:", ";& + .stage__image{margin-top:", ";}}"], space(20), space(-10), mq('m'), space(58), space(-41)))}
  }

  .stage__backLink {
    margin-bottom: ${space(8)};

    svg {
      width: 26px;
      height: 19px;
      transform: translateY(2px) rotate(180deg);
      transition: transform 400ms ease-in-out;
      fill: ${props => props.theme.clientCi.text ? props.theme.clientCi.text : colorSet('color')};
    }

    a:hover {
      svg {
        transform: translateY(2px) rotate(180deg) translateX(${space(1)});
      }
    }
  }

  .stage__news-format {
    position: relative;
    margin-bottom: ${space(4)};

    ${({
  hasPostDate
}) => !hasPostDate && css(["span{padding-left:calc(35px + ", ");", "{padding-left:calc(76px + ", ");}&:before{content:'';position:absolute;top:50%;left:0;width:35px;height:2px;background:", ";transform:translateY(-50%);", "{width:76px;}}}"], space(2), mq('m'), space(4), props => props.theme.clientCi.text ? props.theme.clientCi.text : colorSet('color'), mq('m'))}
  }

  .stage__post-date {
    font-weight: 500;
  }

  .stage__subline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 5'
})};
    ${getProperty('grid-row', {
  m: '3'
})};
  }

  .stage__info {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '7 / span 6'
})};
    ${getProperty('grid-row', {
  m: '3'
})};
    margin-top: ${space(8)};

    ${mq('m')} {
      margin-top: ${space(12)};
    }
  }

  ${({
  variant
}) => (variant === 'standard' || variant === 'home') && css([".stage__subline{", ";}.stage__info{", ";", ";}"], getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 10'
}), getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 10'
}), getProperty('grid-row', {
  m: '4'
}))};

  .stage__excerpt {
    p:last-child {
      padding-bottom: 0;
    }
  }
`;

export default StyledStage;
