import styled$1 from 'styled-components';
import { mq } from '@ccg/core';

const StyledGallery = styled$1.div`
  position: relative;

  .mediaContent__media-wrapper,
  .mediaContent__media-meta {
    ${mq('m', 'max')} {
      grid-column: 1 / span 6;
    }
  }
`;

export default StyledGallery;
