import styled$1 from 'styled-components';
import { colorSet } from '@ccg/core';

const StyledIcon = styled$1.div`
  display: inline;
  white-space: nowrap;
  vertical-align: baseline;

  svg {
    fill: ${colorSet('color')};
  }
`;

export default StyledIcon;
