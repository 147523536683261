const zIndexes = {
  bottom: -1,
  base: 1,
  middle: 2,
  top: 3,
  modal: 99,
  overlay: 999
};

export { zIndexes };
