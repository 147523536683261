const modular = {
  values: {
    size: 0.5,
    form: 0.5,
    volume: 0.5,
    gravity: 0.5,
    acceleration: 0.54
  },
  ranges: {
    fontFamily: [{
      default: 'Founders Grotesk, Helvetica Neue, sans-serif'
    }],
    spacingBase: [5],
    transitionDuration: ['0.4s'],
    cubicBezier: ['0.37, 0, 0.63, 1']
  }
};

export { modular };
