import styled$1 from 'styled-components';
import { space, mq, getProperty } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';

const StyledContainer = styled$1.div`
  position: relative;
  
  .container__headline {
    ${getGrid()}
    margin-top: ${space(10)};
    margin-bottom: ${space(10)};

    ${mq('m')} {
      margin-top: ${space(16)};
    margin-bottom: ${space(16)};
    }

    + div {
      margin-top: 0;
    }

    h2 {
      ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    }
  }
`;

export default StyledContainer;
