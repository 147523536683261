export const randomInt = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

export type FocalPointPosition = 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft';

const positions: FocalPointPosition[] = ['topRight', 'topLeft', 'bottomRight', 'bottomLeft'];

export const getFocalPointAlignment = (
  hasFocalPoint: boolean,
  focalPoint: [number, number],
  randomFallback?: boolean
): FocalPointPosition => {
  if (!hasFocalPoint || !focalPoint) {
    if (randomFallback) {
      return positions[Math.floor(Math.random() * positions.length)];
    }

    return 'topRight';
  }

  const [x, y] = focalPoint;

  if (x >= 0.5 && y < 0.5) {
    return 'topRight';
  }
  if (x >= 0.5 && y >= 0.5) {
    return 'bottomRight';
  }

  if (x < 0.5 && y < 0.5) {
    return 'topLeft';
  }
  if (x < 0.5 && y >= 0.5) {
    return 'bottomLeft';
  }

  return 'topRight';
};

export const randomizeArray = (array: any[]): any[] => {
  const randomizedArray = array;

  for (let i = randomizedArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * i);
    const temp = randomizedArray[i];
    randomizedArray[i] = randomizedArray[j];
    randomizedArray[j] = temp;
  }

  return randomizedArray;
};

export const truncateString = (str: string, length?: number) => {
  const l = length || 200;
  if (!str) return '';
  if (str.length <= l) {
    return str;
  }
  return `${str.substring(0, l)} ...`;
};

export const chunkArray = (array: any[], size: number): any[] => {
  const chunkedArray: any[] = [];
  const copied = [...array];
  const numOfChild = Math.ceil(copied.length / size);
  for (let i = 0; i < numOfChild; i += 1) {
    chunkedArray.push(copied.splice(0, size));
  }
  return chunkedArray;
};

export const clamp = (input: number, lower: number, upper: number) =>
  Math.min(Math.max(input, lower), upper);
