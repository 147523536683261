import styled$1 from 'styled-components';
import { zIndex, mq, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import '../../styles/typography.js';
import { space } from '../../styles/spacing.js';

const StyledCookieLayer = styled$1.div`
  position: fixed !important;
  z-index: ${zIndex('modal')};
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;

  ${mq('m')} {
    max-width: 590px;
    left: ${space(10)};
    bottom: ${space(16)};
  }

  ${mq('l')} {
    left: ${space(13)};
  }

  ${mq('xl')} {
    left: calc((100% - (1440px - 130px)) / 2);
  }

  .cookieLayer__content {
    position: relative;
    padding: ${space(6)} ${space(4)};
    background: ${colorSet('color')};
    color: ${colorSet('background')};

    a:after {
      background: ${colorSet('background')};
    }
  }

  .cookieLayer__text {
    position: relative;
  }

  .cookieLayer__actions {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    button {
      color: ${colorSet('background')};

      &:after {
        background: ${colorSet('background')};
      }
    }
  }

  .cookieLayer__button {
    margin-top: ${space(4)};
    text-align: left;

    &:first-child {
      margin-right: ${space(5)};
      font-weight: 500;

      ${mq('m')} {
        margin-right: ${space(8)};
      }
    }
  }
`;

export default StyledCookieLayer;
