import styled$1, { css } from 'styled-components';
import { Button, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';

const StyledButton = styled$1(Button)`
  position: relative;
  text-transform: ${({
  transform
}) => transform};
  ${({
  variant
}) => getTypography[variant]};
  transition: font-weight 100ms ease-in-out;
  display: inline;
  color: ${colorSet('color')};

  .button__label {
    & > div {
      display: inline-flex;
      align-items: center;
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      background: transparent;
    }
  }

  ${({
  noHover,
  invertedHover,
  variant
}) => !noHover && css(["&:after{content:'';position:absolute;bottom:0;right:0;width:", ";height:", ";background:", ";transition:width 200ms ease-in-out;}&:hover:after{right:auto;left:0;width:", ";}"], invertedHover ? 0 : '100%', variant === 'headline' || variant === 'hero' ? '2px' : '1px', colorSet('color'), invertedHover ? '100%' : 0)}
`;

export default StyledButton;
