import styled$1 from 'styled-components';
import { space, mq, getProperty, colorSet } from '@ccg/core';

const StyledFilter = styled$1.aside`
  position: relative;
  margin: ${space(8)} 0 ${space(4)} 0;

  ${mq('m')} {
    margin-top: ${space(16)};
  }

  .filter__values {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})}
    display: none;

    button {
      position: relative;
      text-align: left;
      display: inline-block;
      margin-bottom: ${space(4)};
      margin-right: ${space(8)};
      color: ${colorSet('color')};
    }
  }

  .filter__values[aria-hidden='false'] {
    display: block;
  }

  .filter__groups {
    grid-column: 1 / span 12;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    button {
      position: relative;
      margin: 0;
      margin-bottom: ${space(4)};
      margin-right: ${space(8)};
      color: ${colorSet('color')};
    }
  }

  .filter__values button,
  .filter__groups button {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background: ${colorSet('color')};
      transition: width 200ms ease-in-out;
    }

    &[aria-pressed='true'],
    &:focus-visible {
      outline: none;

      &:after {
        width: 100%;
      }
    }
  }
`;

export default StyledFilter;
