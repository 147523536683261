import styled$1 from 'styled-components';
import { space, mq } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';

const StyledLogoWall = styled$1.div`
  position: relative;
  margin-top: ${space(-2)};
  margin-bottom: ${space(10)};

  ${mq('m')} {
    margin-top: ${space(-4)};
    margin-bottom: ${space(16)};
  }

  > div {
    ${getGrid({
  default: 6,
  m: 12
})};
    row-gap: ${space(8)};

    ${mq('m')} {
      row-gap: ${space(12)};
    }
  }

  .logoWall__client {
    position: relative;
    height: 66px;
    grid-column-end: span 3;
    margin-right: 33%;

    ${mq('m')} {
      height: 100px;
      grid-column-end: span 4;
      margin-right: calc(22% + 15px);
    }

    ${mq('l')} {
      grid-column-end: span 3;
      margin-right: calc(25% + 30px);
    }
  }

  .logoWall__client-inner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 66px;

    ${mq('m')} {
      padding-bottom: 100px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }
  }
`;

export default StyledLogoWall;
